import React, { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { WuiFormikTextField, WuiModal } from '@uiKit';
import { IBuilderStepBase } from '@workerbase/domain/document';
import { Check, Edit as EditIcon, ErrorOutline } from '@mui/icons-material';
import { Button, IconButton, Typography } from '@mui/material';
import SwitchWithLabel from 'components/Input/SwitchWithLabel';
import { useIntl } from 'react-intl';

export const BuilderStepSettingsCustomVariable = () => {
  const intl = useIntl();
  const formik = useFormikContext<IBuilderStepBase>();
  const [editPrompted, setEditPrompted] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const hasError = formik.errors.variableName && formik.touched.variableName;
  const inputRef = useRef<HTMLInputElement>(null);

  // In case user came back to the step they have left with an error, don't prompt them again to edit it
  useEffect(() => {
    if (formik.errors.variableName && !editPrompted) {
      setEditPrompted(true);
    }
  }, [formik.errors.variableName, editPrompted]);

  useEffect(() => {
    if (editPrompted) {
      inputRef.current?.focus();
    }
  }, [editPrompted]);

  const getIcon = () => {
    if (!editPrompted) {
      return (
        <IconButton size="small" color="primary" onClick={() => setIsEditModalOpen(true)}>
          <EditIcon />
        </IconButton>
      );
    }

    if (hasError) {
      return <ErrorOutline color="disabled" />;
    }

    return <Check color="success" />;
  };

  return (
    <>
      <SwitchWithLabel
        checked={formik.values.isCustomVariableName}
        onChange={(checked) => {
          if (!checked) {
            setIsWarningModalOpen(true);
          } else {
            formik.setFieldValue('isCustomVariableName', true);
            formik.setTouched({ isCustomVariableName: true });
          }
        }}
        labelTranslationId="document.builder.settings.custom-key.toggle"
      />
      {formik.values.isCustomVariableName && (
        <WuiFormikTextField
          disabled={!editPrompted}
          name="variableName"
          label="Element key"
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: getIcon(),
            inputRef,
          }}
        />
      )}

      <WuiModal
        title="document.builder.settings.custom-key.prompt.title"
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        actionButtons={
          <>
            <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              onClick={() => {
                setEditPrompted(true);
                setIsEditModalOpen(false);
              }}
            >
              {intl.formatMessage({ id: 'global.edit' })}
            </Button>
          </>
        }
      >
        <Typography>{intl.formatMessage({ id: 'document.builder.settings.custom-key.prompt.description' })}</Typography>
      </WuiModal>

      <WuiModal
        title="document.builder.settings.custom-key.prompt-disable.title"
        open={isWarningModalOpen}
        onClose={() => setIsWarningModalOpen(false)}
        actionButtons={
          <>
            <Button onClick={() => setIsWarningModalOpen(false)}>{intl.formatMessage({ id: 'global.cancel' })}</Button>
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              color="error"
              onClick={() => {
                setIsWarningModalOpen(false);
                formik.setFieldValue('isCustomVariableName', false);
              }}
            >
              {intl.formatMessage({ id: 'global.disable' })}
            </Button>
          </>
        }
      >
        <Typography>
          {intl.formatMessage({ id: 'document.builder.settings.custom-key.prompt-disable.description' })}
        </Typography>
      </WuiModal>
    </>
  );
};
