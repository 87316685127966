import { DocumentVersionResponse, DocumentVersionUpdateBody } from '@workerbase/api/http/document';
import {
  DocumentBuilder,
  DocumentBuilderStepTypes,
  IBuilderSection,
  IBuilderStep,
  IBuilderStepBase,
  IBuilderStepInfoMedia,
  IBuilderStepInfoText,
  IBuilderStepInputChecklist,
  IBuilderStepInputMedia,
  IBuilderStepInputNumber,
  IBuilderStepInputScancode,
  IBuilderStepInputText,
} from '@workerbase/domain/document';
import { BuilderStepBaseProps } from 'components/DocumentBuilderStep/BuilderStepContainer';
import { ReactNode } from 'react';

export type BuilderStepUI = IBuilderStep & { _id: string };
export type BuilderSectionUI = Omit<IBuilderSection, 'steps'> & { _id: string; steps: BuilderStepUI[] };

export interface StepListItemMeta {
  error: boolean;
  referencedInSteps?: string[]; // step.id[]
  parentStepId?: string;
}
export type BuilderSectionUIWithStepListItem = Omit<BuilderSectionUI, 'steps'> & {
  steps: StepListItem<BuilderStepUI>[];
};
export interface StepListItem<T = BuilderStepUI | BuilderSectionUIWithStepListItem> {
  step: T;
  meta: StepListItemMeta;
}

export type StepProps = {
  value: StepListItem;
  isSelected: boolean;
} & Pick<BuilderStepBaseProps, 'isPreviewMode'>;

export type DocumentBuilderStepComponentType = {
  // container section is a separate component as interface don't overlap
  [key in Exclude<DocumentBuilderStepTypes, DocumentBuilderStepTypes.ContainerSection>]: (
    props: StepProps,
    onChange: (newValue: StepListItem<BuilderStepUI>) => void,
    onDeleteMedia: (mediaId: string) => void,
  ) => ReactNode;
};

export const isBuilderSectionUI = (step: IBuilderStepBase): step is BuilderSectionUI =>
  step.type === DocumentBuilderStepTypes.ContainerSection;

export type BuilderStepInfoTextUI = Omit<IBuilderStepInfoText, 'id'> & { _id: string };
export type BuilderStepInfoMediaUI = Omit<IBuilderStepInfoMedia, 'id'> & { _id: string };
export type BuilderStepInputTextUI = Omit<IBuilderStepInputText, 'id'> & { _id: string };
export type BuilderStepInputMediaUI = Omit<IBuilderStepInputMedia, 'id'> & { _id: string };
export type BuilderStepInputNumberUI = Omit<IBuilderStepInputNumber, 'id'> & { _id: string };
export type BuilderStepInputChecklistUI = Omit<IBuilderStepInputChecklist, 'id'> & { _id: string };
export type BuilderStepInputScancodeUI = Omit<IBuilderStepInputScancode, 'id'> & { _id: string };

export type DocumentVersionUpdateUI = Omit<DocumentVersionUpdateBody, 'steps'> & { steps?: StepListItem[] };
export type DocumentVersionUI = Omit<DocumentVersionResponse, 'documentBuilder'> & {
  documentBuilder?: Omit<DocumentBuilder, 'steps'> & { steps: StepListItem[] };
};
