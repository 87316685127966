import React, { FC, useEffect, useState } from 'react';
import { WuiFileInput, WuiFileInputValue } from '@uiKit/WuiFileInput';
import { WuiMediaDisplay } from '@uiKit';
import { BuilderStepInfoMediaUI, StepListItem } from 'components/DocumentBuilder/types';

interface BuilderStepInfoMediaProps {
  value: StepListItem<BuilderStepInfoMediaUI>;
  onChange: (value: StepListItem<BuilderStepInfoMediaUI>) => void;
  onDeletedMedia: (mediaId: string) => void;
}

export const BuilderStepInfoMedia: FC<BuilderStepInfoMediaProps> = ({ value, onChange, onDeletedMedia }) => {
  const [medias, setMedias] = useState<WuiFileInputValue[]>(
    value.step.media
      ? [{ name: value.step.media.fileName, id: value.step.media._id, type: value.step.media.mimeType }]
      : [],
  );

  useEffect(() => {
    if (value.step.media) {
      setMedias([{ name: value.step.media.fileName, id: value.step.media._id, type: value.step.media.mimeType }]);
    }
  }, [value.step.media]);

  const handleMediaChange = (successfulFiles: WuiFileInputValue[]) => {
    if (!successfulFiles.length) {
      return;
    }

    setMedias(successfulFiles);
    const [media] = successfulFiles;
    onChange({
      ...value,
      step: { ...value.step, media: { _id: media.id, mimeType: media.type, fileName: media.name } },
    });
  };

  return (
    <>
      {value.meta.error ? (
        <WuiFileInput
          error={value.meta.error}
          accept={['video/*', 'audio/*', 'image/*']}
          onSuccess={handleMediaChange}
          onDelete={(mediaId: string) => onDeletedMedia(mediaId)}
        />
      ) : (
        !!medias.length && <WuiMediaDisplay id={medias[0].id} disableExpand />
      )}
    </>
  );
};
