import * as Yup from 'yup';
import { FilterGroupOperator, FilterTypes } from '@workerbase/domain/common';
import { InputChecklistOperators } from '@workerbase/domain/document';
import { StepListItem } from 'components/DocumentBuilder/types';
import { getCustomVariableSchema } from '../components/BuilderStepSettingsCustomVariable';

export const conditionSchema = Yup.object().shape({
  type: Yup.string().oneOf([FilterTypes.CONDITION]).required(),
  name: Yup.string().required(),
  operator: Yup.string().oneOf(Object.values(InputChecklistOperators)).required(),
  value: Yup.string().required('Value is required'),
});

export const groupSchema = Yup.object().shape({
  type: Yup.string().oneOf([FilterTypes.GROUP]).required(),
  groupOperator: Yup.string().oneOf(Object.values(FilterGroupOperator)).required(),
  conditions: Yup.array()
    .of(Yup.lazy((value) => (value.type === FilterTypes.GROUP ? groupSchema : conditionSchema)))
    .required()
    .min(1),
});

export const validationSchema = (stepId: string, steps: StepListItem[]) =>
  Yup.object({
    minSelect: Yup.number().integer().min(1).required(),
    multiple: Yup.boolean().required(),
    required: Yup.boolean(),
    hasFlaggingCriteria: Yup.boolean(),
    flaggingCriteria: Yup.object().when('hasFlaggingCriteria', {
      is: (val) => val === true,
      then: () => groupSchema,
      otherwise: () => Yup.object().nullable(),
    }),
  }).concat(getCustomVariableSchema(stepId, steps));
